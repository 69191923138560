define("ember-freestyle/templates/components/freestyle-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RRfNpbB8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"show\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"hasName\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"FreestyleSection-name\"],[9],[0,\"\\n      \"],[1,[21,\"name\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[14,1,[[27,\"hash\",null,[[\"subsection\"],[[27,\"component\",[\"freestyle-subsection\"],[[\"section\"],[[23,[\"name\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-section.hbs"
    }
  });

  _exports.default = _default;
});