define("ember-freestyle/templates/components/freestyle-subsection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZMJgJUg/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"show\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"hasName\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"FreestyleSubsection-name\"],[9],[0,\"\\n      \"],[1,[21,\"name\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-subsection.hbs"
    }
  });

  _exports.default = _default;
});