define('ember-freestyle/components/freestyle-menu', ['exports', 'ember-freestyle/templates/components/freestyle-menu'], function (exports, _freestyleMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _freestyleMenu.default,
    tagName: '',

    emberFreestyle: Ember.inject.service(),
    menu: Ember.computed.readOnly('emberFreestyle.menu')
  });
});