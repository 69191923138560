define('ember-freestyle/components/freestyle-palette', ['exports', 'ember-freestyle/templates/components/freestyle-palette'], function (exports, _freestylePalette) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    layout: _freestylePalette.default,
    title: 'Freestyle Palette',

    colors: Ember.computed('colorPalette', function () {
      var _this = this;

      return Ember.A(Object.keys(this.get('colorPalette')).map(function (k) {
        return _this.get('colorPalette')[k];
      }));
    })
  });
});