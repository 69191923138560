define("ember-freestyle/templates/components/freestyle-dynamic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rz/6QfKS",
    "block": "{\"symbols\":[\"propertyHash\",\"propertyName\",\"&default\"],\"statements\":[[4,\"freestyle-annotation\",null,null,{\"statements\":[[0,\"  \"],[7,\"h3\"],[11,\"class\",\"FreestyleDynamic-header\"],[9],[1,[21,\"headerTitle\"],false],[10],[0,\"\\n  \"],[7,\"form\"],[11,\"class\",\"FreestyleDynamic-form\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"-each-in\",[[23,[\"dynamicProperties\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"freestyle-dynamic-input\",null,[[\"propertyName\",\"value\",\"options\",\"inputType\",\"description\",\"changeValueTo\"],[[22,2,[]],[22,1,[\"value\"]],[22,1,[\"options\"]],[22,1,[\"inputType\"]],[22,1,[\"description\"]],[27,\"action\",[[22,0,[]],\"changeValueTo\",[22,2,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"freestyle-usage\",[[23,[\"slug\"]]],[[\"title\",\"isDynamic\",\"componentName\",\"dynamicProperties\"],[[23,[\"title\"]],true,[23,[\"componentName\"]],[23,[\"dynamicProperties\"]]]],{\"statements\":[[0,\"  \"],[14,3,[[23,[\"dynamicPropertyValues\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-dynamic.hbs"
    }
  });

  _exports.default = _default;
});