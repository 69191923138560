define('ember-freestyle/components/freestyle-dynamic-input-select-option', ['exports', 'ember-freestyle/templates/components/freestyle-dynamic-input-select-option'], function (exports, _freestyleDynamicInputSelectOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _freestyleDynamicInputSelectOption.default,
    tagName: 'option',
    attributeBindings: ['optionValue:value', 'selected'],

    selected: Ember.computed('optionValue', 'selectedValue', function () {
      return this.get('optionValue') === this.get('selectedValue');
    })
  });
});