define('ember-freestyle/components/freestyle-snippet', ['exports', 'ember-freestyle/templates/components/freestyle-snippet'], function (exports, _freestyleSnippet) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var LANGUAGES = {
    js: 'javascript',
    hbs: 'handlebars',
    css: 'css',
    scss: 'scss',
    less: 'less',
    md: 'markdown'
  };

  exports.default = Ember.Component.extend({
    layout: _freestyleSnippet.default,
    unindent: true,
    initialHighlightComplete: false,
    useDoubleQuotesForStrings: false,
    emberFreestyle: Ember.inject.service(),
    dynamicProperties: Ember.computed(function () {}),

    _unindent: function _unindent(snippet) {
      if (!this.get('unindent')) {
        return snippet;
      }
      var match = void 0,
          min = void 0;
      var unindentedSnippet = snippet;
      var lines = unindentedSnippet.split('\n');
      for (var i = 0; i < lines.length; i++) {
        match = /^\s*/.exec(lines[i]);
        if (match && (typeof min === 'undefined' || min > match[0].length)) {
          min = match[0].length;
        }
      }
      if (typeof min !== 'undefined' && min > 0) {
        unindentedSnippet = unindentedSnippet.replace(new RegExp('(\\n|^)\\s{' + min + '}', 'g'), '$1');
      }
      return unindentedSnippet;
    },
    _dynamafy: function _dynamafy(snippet) {
      var _this = this;

      var dynamicProperties = this.get('dynamicProperties');
      Object.keys(dynamicProperties).forEach(function (property) {
        var propertyValue = Ember.get(dynamicProperties, property + '.value');
        var type = typeof propertyValue === 'undefined' ? 'undefined' : _typeof(propertyValue);
        var quote = _this.get('useDoubleQuotesForStrings') ? '"' : '\'';

        // First, replace in-block dynamic properties
        snippet = snippet.replace('{{dynamic.' + property + '}}', propertyValue);

        var renderedValue = type === 'string' ? '' + quote + propertyValue + quote : propertyValue;
        snippet = snippet.replace('dynamic.' + property, renderedValue);
      });

      return snippet;
    },


    source: Ember.computed('dynamicProperties', 'name', function () {
      var source = this._unindent((this.get('emberFreestyle.snippets')[this.get('name')] || '').replace(/^(\s*\n)*/, '').replace(/\s*$/, ''));

      if (this.get('isDynamic')) {
        source = this._dynamafy(source);
      }

      if (this.get('initialHighlightComplete')) {
        var pre = this.$('pre');

        if (pre[0]) {
          // highlight.js breaks binding, so we need to manually reset the innerText on changes
          pre[0].innerText = source;
          // ...and then do the syntax highlighting again
          this.get('emberFreestyle').highlight(pre[0]);
        }
      }

      return source;
    }),

    didInsertElement: function didInsertElement() {
      var pre = this.get('element').getElementsByTagName('pre');
      if (pre[0] && this.get('source')) {
        this.get('emberFreestyle').highlight(pre[0]);
      }
      this.set('initialHighlightComplete', true);
    },


    language: Ember.computed('name', function () {
      if (this.get('name').indexOf('--notes') >= 0) {
        return 'markdown';
      }

      var match = /\.(\w+)$/i.exec(this.get('name'));
      if (match) {
        return LANGUAGES[match[1]];
      }
    })
  });
});