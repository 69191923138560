define("ember-freestyle/templates/components/freestyle-showdown-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EvKaPCMp",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"markdown-to-html\",null,[[\"markdown\"],[[23,[\"text\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-showdown-content.hbs"
    }
  });

  _exports.default = _default;
});