define("ember-freestyle/templates/components/freestyle-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TsYAotTH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"FreestyleNotes\"],[9],[0,\"\\n  \"],[1,[27,\"freestyle-showdown-content\",null,[[\"text\",\"class\"],[[23,[\"text\"]],\"FreestyleNotes-content\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-notes.hbs"
    }
  });

  _exports.default = _default;
});