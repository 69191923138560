define('ember-freestyle/components/freestyle-usage', ['exports', 'ember-freestyle/templates/components/freestyle-usage'], function (exports, _freestyleUsage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FreestyleUsage = Ember.Component.extend({
    layout: _freestyleUsage.default,
    classNames: ['FreestyleUsage'],
    classNameBindings: ['inline:FreestyleUsage--inline'],

    emberFreestyle: Ember.inject.service(),
    showLabels: Ember.computed.alias('emberFreestyle.showLabels'),
    showNotes: Ember.computed.alias('emberFreestyle.showNotes'),
    showCode: Ember.computed.alias('emberFreestyle.showCode'),
    dynamicProperties: Ember.computed(function () {}),

    show: Ember.computed('emberFreestyle.focus', 'slug', function () {
      var slug = this.get('slug');
      var focus = this.get('emberFreestyle.focus');
      if (focus && slug) {
        return slug.match(focus);
      }
      return true;
    }),
    snippetUsage: Ember.computed('slug', function () {
      return this.get('slug') + '--usage.hbs';
    }),
    snippetHbs: Ember.computed('slug', function () {
      return this.get('slug') + '.hbs';
    }),
    snippetJs: Ember.computed('slug', function () {
      return this.get('slug') + '.js';
    }),
    snippetScss: Ember.computed('slug', function () {
      return this.get('slug') + '.scss';
    }),
    snippetNotesJs: Ember.computed('slug', function () {
      return this.get('slug') + '--notes.js';
    }),
    snippetNotesHbs: Ember.computed('slug', function () {
      return this.get('slug') + '--notes.hbs';
    }),
    snippetNotesScss: Ember.computed('slug', function () {
      return this.get('slug') + '--notes.scss';
    }),
    defaultTheme: Ember.computed.alias('emberFreestyle.defaultTheme'),
    // highlightJsTheme - passed in
    computedTheme: Ember.computed('defaultTheme', 'highlightJsTheme', function () {
      return this.get('highlightJsTheme') || this.get('defaultTheme');
    }),

    hasLabels: Ember.computed.and('showLabels', 'title'),
    hasNotes: Ember.computed.and('showNotes', 'slug'),
    hasCode: Ember.computed.and('showCode', 'slug'),

    didInsertElement: function didInsertElement() {
      this.get('emberFreestyle').ensureHljsTheme(this.get('computedTheme'));
    },


    actions: {
      setFocus: function setFocus() {
        this.set('emberFreestyle.focus', this.get('slug'));
      }
    }
  });

  FreestyleUsage.reopenClass({
    positionalParams: ['slug']
  });

  exports.default = FreestyleUsage;
});