define('ember-freestyle/components/freestyle-subsection', ['exports', 'ember-freestyle/templates/components/freestyle-subsection'], function (exports, _freestyleSubsection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _freestyleSubsection.default,
    classNames: ['FreestyleSubsection'],
    classNameBindings: ['show:is-showing:is-hidden'],
    emberFreestyle: Ember.inject.service(),
    show: Ember.computed('section', 'emberFreestyle.{section,subsection}', 'name', function () {
      var focusedSection = this.get('emberFreestyle.section');
      var showSection = Ember.isBlank(focusedSection) || this.get('section') === focusedSection;

      if (!showSection) {
        return false;
      }

      var focusedSubsection = this.get('emberFreestyle.subsection');
      return Ember.isBlank(focusedSubsection) || this.get('name') === focusedSubsection;
    }),

    showName: Ember.computed.and('emberFreestyle.notFocused', 'name'),
    hasName: Ember.computed.and('showName', 'name'),
    willRender: function willRender() {
      this._super.apply(this, arguments);
      this.get('emberFreestyle').registerSection(this.get('section'), this.get('name'));
    }
  });
});