define('ember-freestyle/components/freestyle-annotation', ['exports', 'ember-freestyle/templates/components/freestyle-annotation'], function (exports, _freestyleAnnotation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FreestyleAnnotation = Ember.Component.extend({
    layout: _freestyleAnnotation.default,
    classNames: ['FreestyleAnnotation'],
    classNameBindings: ['showNotes:is-showing:is-hidden'],

    emberFreestyle: Ember.inject.service(),
    showNotes: Ember.computed('emberFreestyle.{showNotes,focus}', 'slug', function () {
      var slug = this.get('slug');
      var focus = this.get('emberFreestyle.focus');
      if (focus && slug) {
        return slug.match(focus);
      }
      return this.get('emberFreestyle.showNotes');
    })
  });

  FreestyleAnnotation.reopenClass({
    positionalParams: ['slug']
  });

  exports.default = FreestyleAnnotation;
});