define('ember-freestyle/components/freestyle-dynamic-input', ['exports', 'ember-freestyle/templates/components/freestyle-dynamic-input'], function (exports, _freestyleDynamicInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _freestyleDynamicInput.default,
    classNames: ['FreestyleDynamic-input'],

    isCheckbox: Ember.computed.equal('inputType', 'checkbox'),
    isTextarea: Ember.computed.equal('inputType', 'textarea'),
    isSelect: Ember.computed.equal('inputType', 'select'),
    isNumber: Ember.computed.equal('inputType', 'number'),

    inputId: Ember.computed('propertyName', function () {
      return this.get('elementId') + '_' + this.get('propertyName');
    }),

    actions: {
      toggleCheckbox: function toggleCheckbox() {
        var newValue = !this.get('value');
        Ember.get(this, 'changeValueTo')(newValue);
        return false;
      },
      sendChangedValue: function sendChangedValue(newValue) {
        Ember.get(this, 'changeValueTo')(newValue);
      },
      sendChangedNumberValue: function sendChangedNumberValue(newValue) {
        var coercedValue = newValue === '' ? null : Number(newValue);
        Ember.get(this, 'changeValueTo')(coercedValue);
      }
    }
  });
});