define('ember-freestyle/controllers/freestyle', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    emberFreestyle: Ember.inject.service(),

    queryParams: ['l', 'n', 'c', 'm', 'f', 's', 'ss'],

    l: Ember.computed.alias('emberFreestyle.showLabels'),
    n: Ember.computed.alias('emberFreestyle.showNotes'),
    c: Ember.computed.alias('emberFreestyle.showCode'),

    m: Ember.computed.alias('emberFreestyle.showMenu'),

    f: Ember.computed.alias('emberFreestyle.focus'),

    s: Ember.computed.alias('emberFreestyle.section'),
    ss: Ember.computed.alias('emberFreestyle.subsection')
  });
});