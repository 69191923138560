define("ember-freestyle/templates/components/freestyle-variant-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rECTu+0b",
    "block": "{\"symbols\":[\"variantKey\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"FreestyleCollection-variantList\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"variants\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[12,\"class\",[28,[\"FreestyleCollection-variantListItem \",[27,\"if\",[[27,\"variant-eq\",[[23,[\"activeKey\"]],[22,1,[]]],null],\"FreestyleCollection-variantListItem--active\"],null]]]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onClickItem\",[22,1,[]]],null]],[9],[0,\"\\n      \"],[1,[22,1,[]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-variant-list.hbs"
    }
  });

  _exports.default = _default;
});