define('ember-mapbox-gl/components/mapbox-gl', ['exports', 'ember-mapbox-gl/templates/components/mapbox-gl', 'mapbox-gl', 'ember-mapbox-gl/utils/noop'], function (exports, _mapboxGl, _mapboxGl2, _noop) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mapboxGl.default,

    initOptions: null,
    mapLoaded: _noop.default,

    init: function init() {
      this._super.apply(this, arguments);

      this.map = null;
      this.glSupported = _mapboxGl2.default.supported();

      var mbglConfig = Ember.getOwner(this).resolveRegistration('config:environment')['mapbox-gl'];
      (true && !(mbglConfig) && Ember.assert('mapbox-gl config is required in config/environment', mbglConfig));
      (true && !(typeof mbglConfig.accessToken === 'string') && Ember.assert('mapbox-gl config must have an accessToken string', typeof mbglConfig.accessToken === 'string'));


      _mapboxGl2.default.accessToken = mbglConfig.accessToken;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.glSupported) {
        Ember.run.scheduleOnce('afterRender', this, this._setup);
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (this.map !== null) {
        // some map users may be late doing cleanup (seen with mapbox-draw-gl), so don't remove the map until the next tick
        Ember.run.next(this.map, this.map.remove);
      }
    },
    _setup: function _setup() {
      var mbglConfig = Ember.getOwner(this).resolveRegistration('config:environment')['mapbox-gl'];
      var options = Ember.assign({}, mbglConfig.map, Ember.get(this, 'initOptions'));
      options.container = this.element;

      var map = new _mapboxGl2.default.Map(options);
      map.once('load', Ember.run.bind(this, this._onLoad, map));
    },
    _onLoad: function _onLoad(map) {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      this.mapLoaded(map);

      Ember.set(this, 'map', map);
    }
  });
});