define('ember-freestyle/components/freestyle-section', ['exports', 'ember-freestyle/templates/components/freestyle-section'], function (exports, _freestyleSection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _freestyleSection.default,
    classNames: ['FreestyleSection'],
    classNameBindings: ['show:FreestyleSection--showing:FreestyleSection--hidden'],

    emberFreestyle: Ember.inject.service(),
    show: Ember.computed('emberFreestyle.section', 'name', function () {
      var focusedSection = this.get('emberFreestyle.section');
      return Ember.isBlank(focusedSection) || this.get('name') === focusedSection;
    }),

    showName: Ember.computed.and('emberFreestyle.notFocused', 'name'),
    hasName: Ember.computed.and('showName', 'name'),

    willRender: function willRender() {
      this._super.apply(this, arguments);
      this.get('emberFreestyle').registerSection(this.get('name'));
    }
  });
});