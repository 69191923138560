define("ember-freestyle/templates/components/freestyle-palette-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NBjlYi/K",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"FreestylePaletteItem\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"FreestylePaletteItem-color\"],[12,\"style\",[21,\"paletteStyle\"]],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"FreestylePaletteItem-info\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"FreestylePaletteItem-hex\"],[9],[1,[23,[\"color\",\"base\"]],false],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"FreestylePaletteItem-name\"],[9],[1,[23,[\"color\",\"name\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-palette-item.hbs"
    }
  });

  _exports.default = _default;
});