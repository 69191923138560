define("ember-freestyle/templates/components/freestyle-snippet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ivLZCdKO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"title\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"FreestyleSnippet-title\"],[9],[0,\"\\n    \"],[1,[21,\"title\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"source\"]]],null,{\"statements\":[[7,\"pre\"],[12,\"class\",[21,\"language\"]],[9],[1,[21,\"source\"],false],[10],[0,\"\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-snippet.hbs"
    }
  });

  _exports.default = _default;
});