define("ember-freestyle/templates/components/freestyle-palette", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VQg2jAMU",
    "block": "{\"symbols\":[\"color\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"FreestylePalette\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"FreestylePalette-title\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"FreestylePalette-description\"],[9],[1,[21,\"description\"],false],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"colors\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"freestyle-palette-item\",null,[[\"color\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[14,2],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-palette.hbs"
    }
  });

  _exports.default = _default;
});