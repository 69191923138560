define("ember-freestyle/templates/components/freestyle-collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+ZuK9nqS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"hasLabels\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"FreestyleCollection-title\"],[9],[0,\"\\n    \"],[1,[21,\"title\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"showVariantList\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"freestyle-variant-list\",null,[[\"variants\",\"activeKey\",\"onClickVariant\"],[[23,[\"variants\"]],[23,[\"activeKey\"]],[27,\"action\",[[22,0,[]],\"setKey\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[14,1,[[27,\"hash\",null,[[\"variant\"],[[27,\"component\",[\"freestyle-variant\"],[[\"collection\"],[[22,0,[]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-collection.hbs"
    }
  });

  _exports.default = _default;
});