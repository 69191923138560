define("@ember-decorators/utils/-private/descriptor", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isComputedDescriptor = isComputedDescriptor;
  _exports.computedDescriptorFor = computedDescriptorFor;
  var DESCRIPTOR = '__DESCRIPTOR__';

  function isCPGetter(getter) {
    // Hack for descriptor traps, we want to be able to tell if the function
    // is a descriptor trap before we call it at all
    return getter !== null && typeof getter === 'function' && getter.toString().indexOf('CPGETTER_FUNCTION') !== -1;
  }

  function isDescriptorTrap(possibleDesc) {
    if (false
    /* HAS_DESCRIPTOR_TRAP */
    && true
    /* DEBUG */
    ) {
      return possibleDesc !== null && (0, _typeof2.default)(possibleDesc) === 'object' && possibleDesc[DESCRIPTOR] !== undefined;
    } else {
      throw new Error('Cannot call `isDescriptorTrap` in production');
    }
  }

  function isComputedDescriptor(possibleDesc) {
    return possibleDesc !== null && ((0, _typeof2.default)(possibleDesc) === 'object' || typeof possibleDesc === 'function') && possibleDesc.isDescriptor;
  }

  function computedDescriptorFor(obj, keyName) {
    (true && !(obj !== null) && Ember.assert('Cannot call `descriptorFor` on null', obj !== null));
    (true && !(obj !== undefined) && Ember.assert('Cannot call `descriptorFor` on undefined', obj !== undefined));
    (true && !((0, _typeof2.default)(obj) === 'object' || typeof obj === 'function') && Ember.assert("Cannot call `descriptorFor` on ".concat((0, _typeof2.default)(obj)), (0, _typeof2.default)(obj) === 'object' || typeof obj === 'function'));

    if (true
    /* HAS_NATIVE_COMPUTED_GETTERS */
    ) {
      var meta = Ember.meta(obj);

      if (meta !== undefined && (0, _typeof2.default)(meta._descriptors) === 'object') {
        // TODO: Just return the standard descriptor
        if (true) {
          return meta._descriptors.get(keyName);
        } else {
          return meta._descriptors[keyName];
        }
      }
    } else if (Object.hasOwnProperty.call(obj, keyName)) {
      var _Object$getOwnPropert = Object.getOwnPropertyDescriptor(obj, keyName),
          possibleDesc = _Object$getOwnPropert.value,
          possibleCPGetter = _Object$getOwnPropert.get;

      if (true
      /* DEBUG */
      && false
      /* HAS_DESCRIPTOR_TRAP */
      && isCPGetter(possibleCPGetter)) {
        possibleDesc = possibleCPGetter.call(obj);

        if (isDescriptorTrap(possibleDesc)) {
          return possibleDesc[DESCRIPTOR];
        }
      }

      return isComputedDescriptor(possibleDesc) ? possibleDesc : undefined;
    }
  }
});