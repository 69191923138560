define('ember-freestyle/components/freestyle-typeface', ['exports', 'ember-freestyle/templates/components/freestyle-typeface'], function (exports, _freestyleTypeface) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _freestyleTypeface.default,
    classNames: ['FreestyleTypeface'],
    attributeBindings: ['fontFamilyStyle:style'],

    fontFamilyStyle: Ember.computed('fontFamily', function () {
      return Ember.String.htmlSafe('font-family: ' + this.get('fontFamily') + ';');
    })
  });
});