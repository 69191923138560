define("ember-freestyle/templates/components/freestyle-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o1CgSGzt",
    "block": "{\"symbols\":[\"section\",\"subsection\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"FreestyleMenu\"],[9],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"FreestyleMenu-item\"],[9],[0,\"\\n\"],[4,\"link-to\",[[27,\"query-params\",null,[[\"s\",\"ss\",\"f\"],[null,null,null]]]],[[\"class\"],[\"FreestyleMenu-itemLink\"]],{\"statements\":[[0,\"      All\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"menu\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[11,\"class\",\"FreestyleMenu-item\"],[9],[0,\"\\n\"],[4,\"link-to\",[[27,\"query-params\",null,[[\"f\",\"s\",\"ss\"],[null,[22,1,[\"name\"]],null]]]],[[\"class\"],[\"FreestyleMenu-itemLink\"]],{\"statements\":[[0,\"        \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[22,1,[\"subsections\"]]],null,{\"statements\":[[0,\"        \"],[7,\"ul\"],[11,\"class\",\"FreestyleMenu-submenu\"],[9],[0,\"\\n          \"],[7,\"li\"],[11,\"class\",\"FreestyleMenu-submenuItem\"],[9],[0,\"\\n\"],[4,\"link-to\",[[27,\"query-params\",null,[[\"f\",\"s\",\"ss\"],[null,[22,1,[\"name\"]],[22,2,[\"name\"]]]]]],[[\"class\"],[\"FreestyleMenu-submenuItemLink\"]],{\"statements\":[[0,\"              \"],[1,[22,2,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-menu.hbs"
    }
  });

  _exports.default = _default;
});