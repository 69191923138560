define("ember-freestyle/templates/components/freestyle-typeface", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2yl1eFZ+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"FreestyleTypeface-previewHero\"],[9],[0,\"Aa\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"FreestyleTypeface-previewSampleContainer\"],[9],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"FreestyleTypeface-previewSample\"],[9],[0,\"A B C D E F G H I J K L M N O P Q R S T U V W X Y Z\"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"FreestyleTypeface-previewSample\"],[9],[0,\"a b c d e f g h i j k l m n o p q r s t u v w x y z\"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"FreestyleTypeface-previewSample\"],[9],[0,\"0 1 2 3 4 5 6 7 8 9\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-freestyle/templates/components/freestyle-typeface.hbs"
    }
  });

  _exports.default = _default;
});