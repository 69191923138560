define('ember-freestyle/components/freestyle-dynamic', ['exports', 'ember-freestyle/templates/components/freestyle-dynamic'], function (exports, _freestyleDynamic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _freestyleDynamic.default,
    classNames: ['FreestyleDynamic'],
    headerTitle: 'Dynamic Properties:',

    // Need this separate property for freestyle-dynamic's dynamic.<property> to work
    dynamicPropertyValues: Ember.computed('dynamicProperties', function () {
      var _this = this;

      var dynamicPropertyValues = {};
      var dynamicProperties = this.get('dynamicProperties');
      Object.keys(dynamicProperties).forEach(function (propertyName) {
        Ember.set(dynamicPropertyValues, propertyName, _this.get('dynamicProperties.' + propertyName + '.value'));
      });

      return dynamicPropertyValues;
    }),

    actions: {
      changeValueTo: function changeValueTo(property, newValue) {
        // Make a copy and then reset dynamicProperties, to force template changes
        var dynamicProperties = Ember.assign({}, this.get('dynamicProperties'));
        Ember.set(dynamicProperties, property + '.value', newValue);

        this.set('dynamicProperties', dynamicProperties);
      }
    }
  }).reopenClass({
    positionalParams: ['slug']
  });
});